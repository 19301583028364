import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

import { getColor } from '../util/theme';

const Wrapper = styled.main`
  max-width: 1200px;

  padding: 200px 25px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: ${getColor('complimentaryBlue')};
    font-size: 24px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <h1>Page not found!</h1>
      <p>
        <Link to="/">Click here to return to the homepage.</Link>
      </p>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
